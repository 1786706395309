import React, { Component } from 'react';
import {
  Alert,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import axios from 'supports/api';
import 'react-datepicker/dist/react-datepicker.css';
import { TextForm } from 'components/CustomForms';

const INITIAL_STATE = {
  subscriptions: [],
};

const INITIAL_FORM = {
  subscription: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Subscription',
  },
  discount: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    error: 'Input discount',
  },
  notes: {
    value: '',
    valid: false,
    invalid: false,
    error: 'Input notes',
  },
};

// Object creator
const createTransactionItem = ({
  id = 0, // universal
  price = 0, // universal
  discount = 0, // subscription
  duration = 0, // subscription
  userId = 0, // subscription
  notes = null,
  productType = null, // universal
  itemDescription = null, // for front-end purposes
}) => {
  return {
    id,
    price,
    discount,
    duration,
    userId,
    notes,
    productType,
    itemDescription,
  };
};

class AddSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_FORM,
    };
  }

  componentDidMount() {
    axios
      .get(`v2/subscription`)
      .then(({ data }) => {
        this.setState({ subscriptions: data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  resetState = () => {
    this.setState({ ...INITIAL_FORM });
  };

  validateForm = async (form) => {
    let result = 0;

    await Object.keys(form).forEach(async (obj) => {
      if (!form[obj].valid) {
        await this.setState((prevState) => ({
          [obj]: { ...prevState[obj], invalid: true },
        }));
        result += 1;
      }
    });

    if (result > 0) {
      return false;
    }
    return true;
  };

  onAddClick = () => {
    const { subscription, discount, notes } = this.state;

    const validation = this.validateForm({ subscription }); // returns a promise

    validation.then(async (valid) => {
      if (valid) {
        const selectedSubscription = JSON.parse(this.state.subscription.value);
        const itemDescription = (
          <div className="d-flex flex-column">
            <div>{selectedSubscription.name}</div>
            <div className="text-muted font-weight-lighter">{`User: ${this.props.userData.firstName} ${this.props.userData.lastName}`}</div>
          </div>
        );

        const transactionItem = createTransactionItem({
          id: selectedSubscription.id, // universal
          price: selectedSubscription.price, // universal
          discount: discount.value, // universal
          duration: selectedSubscription.duration, // subscription
          userId: this.props.userData.id, // subscription
          productType: 'subscription', // universal
          notes: notes.value,
          itemDescription, // universal
        });

        await this.props.addTransactionItem([transactionItem]);
        this.props.toggle();
      } else {
        alert('There are invalid fields');
      }
    });
  };

  handleDiscountInput = (value) => {
    const newValue = value.split(',').join('');

    let number = parseInt(newValue, 0);
    let displayValue = number.toLocaleString('en-US');

    const subscription = JSON.parse(this.state.subscription.value);

    let price = 0;
    if (subscription) {
      price = subscription.price;
    }

    if (number > price) {
      return null;
    }

    if (newValue === '') {
      number = 0;
      displayValue = '0';
    }

    this.setState((prevState) => ({
      ...prevState,
      discount: { ...prevState.discount, value: number, displayValue },
    }));

    return null;
  };

  handleSelect = (state, value) => {
    const checkState = (checkedState) => {
      const { subscription } = INITIAL_FORM;
      switch (checkedState) {
        case 'categoryId':
          return { subscription, hasInstallments: false };
        default:
          return {};
      }
    };

    const formReset = checkState(state);
    let valid = false;
    let invalid = true;
    if (value !== 0) {
      valid = true;
      invalid = false;
    }

    this.setState((prevState) => ({
      ...prevState,
      ...formReset,
      [state]: { value, valid, invalid },
    }));
  };

  handleInput = (state, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value, valid: true, invalid: false },
    }));
  };

  PriceDetails = ({ item, discount = 0 }) => {
    const subscription = JSON.parse(item);
    let price = 0;

    if (subscription) {
      price = subscription.price;
    }

    const totalPrice = price - discount;
    return (
      <div>
        <div className="text-gray font-weight-bold mb-2">Total Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            disabled
            value={totalPrice.toLocaleString('en-US')}
          />
        </InputGroup>
      </div>
    );
  };

  DiscountInput = ({ disabled, displayValue, error }) => {
    return (
      <div style={{ marginBottom: '24px' }}>
        <div className="text-gray font-weight-bold mb-2">Discount</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            disabled={disabled}
            value={displayValue}
            onChange={({ target }) => this.handleDiscountInput(target.value)}
          />
          <InputGroupAddon addonType="append">
            <Button color="danger" disabled>
              -
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </div>
    );
  };

  RenderSubscriptions = ({ value, valid, invalid, error }) => {
    const subscriptionItem = this.state.subscriptions.map((item) => {
      return (
        <option key={JSON.stringify(item)} value={JSON.stringify(item)}>
          {item.name}
        </option>
      );
    });

    if (this.state.subscriptions.length > 0) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">
            Pilih Subscription
          </div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) =>
              this.handleSelect('subscription', target.value)
            }
          >
            <option value={0} disabled hidden>
              Pilih Subscription
            </option>
            {subscriptionItem}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    return null;
  };

  RenderAlert = () => {
    const { invalid, error } = this.state.subscription;

    if (invalid) {
      return (
        <Alert color="danger" className="py-1">
          {error}
        </Alert>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
        onClosed={this.resetState}
      >
        <ModalHeader>Add Subscription Item</ModalHeader>
        <ModalBody>
          <Card className="my-1">
            <CardHeader>Select Subscription</CardHeader>
            <CardBody>
              <this.RenderAlert />
              <this.RenderSubscriptions {...this.state.subscription} />
            </CardBody>
          </Card>
          <Card className="my-1">
            <CardHeader>Price Details</CardHeader>
            <CardBody>
              {Boolean(this.state.subscription.value) && (
                <this.DiscountInput
                  displayValue={this.state.discount.displayValue}
                  error={this.state.discount.error}
                />
              )}

              <this.PriceDetails
                item={this.state.subscription.value}
                discount={this.state.discount.value}
              />

              {Boolean(this.state.subscription.value) && (
                <div style={{ marginTop: '24px' }}>
                  <TextForm
                    {...this.state.notes}
                    label="Notes *optional"
                    onInput={(value) => this.handleInput('notes', value)}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row float-right">
            <Button
              color="success"
              style={{ backgroundColor: '#53b59f' }}
              onClick={this.onAddClick}
            >
              Add
            </Button>
            <Button className="ml-2" color="danger" onClick={this.props.toggle}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddSubscriptionModal;
