import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'supports/api';
import { useSelector } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Table,
  CustomInput,
  Input,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import AddSubscriptionModal from 'components/Transactions/AddSubscriptionModal';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import AddServiceModal from './modals/AddServiceModal';
import AddProgramModal from './modals/AddProgramModal';
import AddOnlineCourseModal from './modals/AddOnlineCourseModal';
import AddTransactionPromosModal from './modals/AddTransactionPromosModal';
import VerifyUser from './components/VerifyUser';
import TransactionItem from './components/TransactionItem';
import ReferralCodeInput from './components/ReferralCodeInput';
import DeadlinePicker from './components/DeadlinePicker';
import LoadingButton from './components/LoadingButton';
import AddAlumniEventModal from './modals/AddAlumniEventModal';
import TransactionInstallment from './components/TransactionInstallment';
import {
  transactionInstallmentPaymentSchema,
  transactionInstallmentSchema,
} from './components/TransactionInstallment/schema/TransactionInstallmentSchema';
import ApplyVoucherModal from './modals/ApplyVoucherModal';

const checkTNCReq = async (transactionList) => {
  let hasTNC = false;

  transactionList.forEach((item) => {
    if (item?.productType === 'program') {
      hasTNC = true;
    }
    if (['Biaya Registrasi Program Dana Cita'].includes(item?.data?.name)) {
      hasTNC = true;
    }
  });

  return hasTNC;
};

const INITIAL_FORM = {
  transactionList: [],
  currencyId: 1, // IDR in db
  subtotal: 0,
  totalDiscount: 0,
  totalPrice: 0,
  referralCode: '',
  transactionPromos: [],
};

const CreateTransactionPage = (props) => {
  const [state, setState] = useState({ ...INITIAL_FORM });
  const [taxBorneByCustomer, setTaxBorneByCustomer] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addProgramModal, setAddProgramModal] = useState(false);
  const [addSubscriptionModal, setAddSubscriptionModal] = useState(false);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [addOnlineCourseModal, setAddOnlineCourseModal] = useState(false);
  const [addAlumniEventModal, setAlumniEventModal] = useState(false);
  const [addTransactionPromosModal, setAddTransactionPromosModal] = useState(
    false,
  );
  const [applyVoucherModal, setApplyVoucherModal] = useState({
    isOpen: false,
    selectedItem: 0,
  });

  const [deadline, setDeadline] = useState(moment().add(3, 'days').toDate());
  const [currencies, setCurrencies] = useState([]);
  const [transactionPromosList, setTransactionPromosList] = useState([]);
  const [selectedReferralCode, setSelectedReferralCode] = useState(null);
  const [selectedVoucherCode, setSelectedVoucherCode] = useState(null);

  const [loadingFetchPromoList, setLoadingFetchPromoList] = useState(true);

  const trxUser = useSelector((globalState) => globalState.trxUser);

  const installmentFormRef = useRef();

  const selectedCurrency = currencies.find(
    (currency) => currency.id === state?.currencyId,
  );

  const fetchCurrencies = async () => {
    try {
      const { data } = await axios.get('/v2/currency');

      setCurrencies(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPromos = async () => {
    setLoadingFetchPromoList(true);
    try {
      const { data } = await axios.get('/v2/transaction-promos');
      setTransactionPromosList(data.rows);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFetchPromoList(false);
    }
  };

  useEffect(() => {
    fetchCurrencies();
    fetchPromos();
  }, []);

  const isReferralCodeEnabled = useMemo(
    () =>
      state?.transactionList?.length === 1 &&
      state?.transactionList[0]?.qty === 1 &&
      state?.transactionList[0]?.productType === 'program',
    [state?.transactionList],
  );

  const parseItems = ({ list, referralCode, voucherCode }) => {
    const body = {
      email: trxUser.email,
      transactionDetails: list,
      referralCode,
      currencyId: state.currencyId,
      voucherCode,
    };

    axios
      .post(`/admin/parse-bills`, body)
      .then((res) => {
        const transactionList = res.data.result;
        setState({
          ...state,
          transactionList,
          referralCode: res.data.referralCode,
          voucherCode: res.data.voucherCode,
          subtotal: res.data.subtotal,
          totalDiscount: res.data.totalDiscount,
          totalPrice: res.data.totalPrice,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          alert(err?.response?.data?.message);
        }
      });
  };

  const handleAddTransactionPromos = (newPromo) => {
    if (state.transactionPromos.find((val) => val.id === newPromo.id)) {
      alert('Promo hanya bisa ditambahkan sekali');
    } else {
      const newPromos = state.transactionPromos.concat(newPromo ?? []);
      setState({ ...state, transactionPromos: newPromos });
    }
  };
  const handleDeleteTransactionPromo = (index) => {
    const newPromos = state.transactionPromos;
    newPromos.splice(index, 1);
    setState({ ...state, transactionPromos: newPromos });
  };

  const addTransactionItem = async (transactionItem) => {
    const newList = state.transactionList.concat(transactionItem ?? []);
    parseItems({
      list: newList,
      referralCode: state.referralCode,
      voucherCode: state.voucherCode,
    });
  };

  const deleteTransactionItem = async (targetIndex) => {
    const newList = state.transactionList.filter(
      (value, index) => index !== targetIndex,
    );

    let { voucherCode } = state;

    if (newList.length < 1) {
      voucherCode = null;
      setSelectedVoucherCode(null);
    }

    parseItems({
      list: newList,
      referralCode: state.referralCode,
      voucherCode,
    });
  };

  const applyReferralCode = ({
    isInstallment,
    referralCodeData,
    installmentTuitionFee,
  }) => {
    if (referralCodeData || selectedReferralCode) {
      const { code, nominal, isPercentage } =
        referralCodeData || selectedReferralCode || {};

      if (isInstallment) {
        parseItems({ list: state.transactionList });

        const tuitionFeeValue =
          installmentTuitionFee || installmentFormRef.current.values.tuitionFee;

        const totalDiscount = isPercentage
          ? tuitionFeeValue * (Number(nominal) / 100)
          : Number(nominal);

        installmentFormRef.current.setFieldValue('discount', totalDiscount);
        installmentFormRef.current.setFieldValue('readOnly', false);
      } else {
        parseItems({ list: state.transactionList, referralCode: code });
      }
    }
  };

  const addReferralCode = async (referralCode) => {
    try {
      const res = await axios.post('/v2/referral-code/verify-referral-code', {
        // reminder to rename url
        code: referralCode,
      });

      if (res.data?.status === 'SUCCESS') {
        setSelectedReferralCode(res.data.data);

        applyReferralCode({
          isInstallment: installmentFormRef.current.values.isInstallment,
          referralCodeData: res.data.data,
        });
      } else {
        alert(res.data?.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  const deleteReferralCode = () => {
    parseItems({
      list: state.transactionList,
      referralCode: '',
      voucherCode: selectedVoucherCode?.number,
    });
    setSelectedReferralCode(null);
    installmentFormRef.current.setFieldValue('discount', 0);
    installmentFormRef.current.setFieldValue('readOnly', false);
  };

  const addVoucherCode = async (voucherCode) => {
    try {
      const voucherTransactionItem =
        state.transactionList[applyVoucherModal.selectedItem];

      const otherTransactionItems = state.transactionList.filter(
        (_, itmIndex) => itmIndex !== applyVoucherModal.selectedItem,
      );

      const { data } = await axios.post('/v2/voucher/validate', {
        voucherCode,
        voucherTransactionItem,
        otherTransactionItems,
      });

      if (data?.status === 'SUCCESS') {
        const newTransactionItems = state.transactionList;

        newTransactionItems[
          applyVoucherModal.selectedItem
        ].voucherCode = voucherCode;

        parseItems({
          list: newTransactionItems,
          referralCode: selectedReferralCode?.code,
        });
      }

      setApplyVoucherModal({ isOpen: false, selectedItem: 0 });
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const onDeleteVoucher = (transactionItemIndex) => {
    const copiedTransactionList = state.transactionList;
    copiedTransactionList[transactionItemIndex].voucherCode = null;
    parseItems({
      list: state.transactionList,
      voucherCode: '',
      referralCode: selectedReferralCode?.code,
    });
    setSelectedVoucherCode(null);
  };

  const validateForm = async (form) => {
    const { transactionList } = state;
    let result = '';

    await Object.keys(form).forEach(async (obj) => {
      // if(obj === "hasInstallments" && !form[obj]){
      //     exclude = ["hasInstallments", "installmentStartMonth", "months", "baseDate"]
      // }
      if (!form[obj]) {
        result += `${obj}\n`;
      }
    });

    if (!(trxUser?.userData?.identityCard || trxUser?.userData?.npwp)) {
      throw new Error(
        'Nomor KTP atau NPWP harus terisi untuk menciptakan Transaksi',
      );
    }

    if (result) {
      const errorMessage = `There are invalid fields:\n${result}`;
      throw new Error(errorMessage);
    }
    if (transactionList.length === 0) {
      throw new Error('No items added');
    }

    return true;
  };

  const createTransaction = async () => {
    try {
      const {
        transactionList,
        referralCode,
        currencyId,
        transactionPromos,
        voucherCode,
      } = state;

      if (installmentFormRef.current.values.isInstallment) {
        // Validate Transaction Installment
        await transactionInstallmentSchema.validate(
          installmentFormRef.current.values,
          {
            abortEarly: false,
          },
        );

        const {
          accountReceivable,
          installmentPayments,
        } = installmentFormRef.current.values;

        let totalNominalPayment = 0;

        // Guard for nominal payment
        installmentPayments.forEach((installmentPayment) => {
          totalNominalPayment += installmentPayment.nominal;
        });

        // If nominal payment less than account receivable then throw error
        if (totalNominalPayment < accountReceivable) {
          throw new Error('Total nominal payment yang di-input kurang');
        }

        // Validate Transaction Installment Payment
        await transactionInstallmentPaymentSchema.validate(
          installmentPayments,
          {
            abortEarly: false,
          },
        );
      }

      const haveDiscount = transactionList.find((data) => data.discount > 0);

      if (haveDiscount && transactionPromos?.length <= 0) {
        throw new Error(
          'Transaksi dengan discount diwajibkan untuk menyertakan Transaction Promos',
        );
      }

      const { email, userData } = trxUser;
      await validateForm({
        email,
        validUser: userData.id,
        deadline,
      });
      setIsLoading(true);

      const hasTNC = await checkTNCReq(transactionList);

      const data = {
        email,
        transactionDetails: transactionList,
        deadline,
        taxBorneByCustomer,
        referralCode: referralCode || selectedReferralCode?.code,
        voucherCode: voucherCode || selectedVoucherCode?.number,
        hasTNC,
        currencyId,
        transactionPromos,
        isDeposit,
        ...(installmentFormRef.current.values.isInstallment && {
          transactionInstallment: {
            ...installmentFormRef.current.values,
            downPayment:
              installmentFormRef.current.values.downPayment +
              installmentFormRef.current.values.dpOtherTransaction,
          },
        }),
      };

      setTimeout(() => {
        axios
          .post(`/admin/create/transaction`, data)
          .then(() => {
            setIsLoading(false);
            alert('Create transaction success');
            props.history.push('/admin/transactions');
          })
          .catch(({ response }) => {
            setIsLoading(false);
            console.log(response.data);
            const { message, error } = response.data;
            alert(`${error}`);
          });
      }, 400); // Loads for a minimal of 0.4s
    } catch (err) {
      alert(err);

      if (installmentFormRef.current.values.isInstallment) {
        Object.keys(installmentFormRef.current.values).forEach((valueKey) => {
          installmentFormRef.current.setFieldTouched(valueKey, true);
        });
      }
    }
  };

  // To generate down payment based on totalPrice data
  useMemo(() => {
    if (installmentFormRef.current) {
      installmentFormRef.current.setFieldValue('readOnly', false);
      installmentFormRef.current.setFieldValue('downPayment', state.totalPrice);
    }

    return state.totalPrice;
  }, [state.totalPrice]);

  const onApplyVoucher = (selectedItem) => {
    setApplyVoucherModal({
      isOpen: true,
      selectedItem,
    });
  };

  const RenderItemList = () => {
    if (trxUser?.userData?.id) {
      const arrJSX = [];
      let registrationFeeBase = 0;
      let registrationFeeQty = 0;

      (state.transactionList ?? []).forEach((item, index) => {
        if (item?.data?.registrationFee) {
          registrationFeeBase = item?.data?.registrationFee;
          registrationFeeQty += 1;
        }

        arrJSX.push(
          <TransactionItem
            item={item}
            onDelete={() => deleteTransactionItem(index)}
            onApplyVoucher={() => onApplyVoucher(index)}
            onDeleteVoucher={() => onDeleteVoucher(index)}
          />,
        );
      });

      return (
        <Card className="mb-2">
          <CardHeader>
            <div className="d-flex flex-row mb-2 align-items-center">
              <div style={{ width: '20%' }}>
                <b>Select Currency</b>
              </div>
              <div style={{ width: '80%' }}>
                <Input
                  id="currencySelect"
                  name="select"
                  type="select"
                  value={state?.currencyId}
                  onChange={(e) => {
                    setState({
                      ...state,
                      ...INITIAL_FORM,
                      currencyId: Number(e.target.value),
                    });
                  }}
                >
                  {currencies?.map((currency) => {
                    return (
                      <option
                        value={currency?.id}
                      >{`${currency.name} - ${currency.code}`}</option>
                    );
                  })}
                </Input>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
              <div className="my-auto d-flex flex-column font-weight-bold">
                <CustomInput
                  type="checkbox"
                  id="taxBorneByCustomer"
                  label="PPN dipungut mandiri oleh customer"
                  checked={taxBorneByCustomer}
                  onChange={(e) => setTaxBorneByCustomer(e.target.checked)}
                />

                <CustomInput
                  type="checkbox"
                  id="isDeposit"
                  label="Tandai sebagai Transaksi Deposit"
                  checked={isDeposit}
                  onChange={(e) => setIsDeposit(e.target.checked)}
                />
              </div>
              <div className="d-flex flex-row flex-wrap">
                <Button onClick={() => setAddProgramModal(true)}>
                  +Program
                </Button>
                <Button
                  onClick={() => setAddSubscriptionModal(true)}
                  className="ml-1"
                >
                  +Subscription
                </Button>
                <Button
                  onClick={() => setAddServiceModal(true)}
                  className="ml-1"
                >
                  +Service
                </Button>
                <Button
                  onClick={() => setAddOnlineCourseModal(true)}
                  className="ml-1"
                >
                  +Online Course
                </Button>
                <Button
                  onClick={() => setAlumniEventModal(true)}
                  className="ml-1"
                >
                  +Alumni Event
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Alert
              color="danger"
              className=""
              isOpen={!state.transactionList.length}
            >
              No items in list!
            </Alert>
            <Table hover>
              <thead>
                <tr>
                  <th colSpan={3}>Description</th>
                  <th colSpan={1}>
                    <span className="float-right">Price</span>
                  </th>
                  <th colSpan={1}>
                    <span className="float-right">QTY</span>
                  </th>
                  <th colSpan={1}>
                    <span className="float-right">Nominal</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {arrJSX}
                {registrationFeeQty > 0 && (
                  <TransactionItem
                    item={{
                      price: registrationFeeBase,
                      qty: registrationFeeQty,
                      productType: 'registrationFee',
                      currency: selectedCurrency?.code || 'IDR',
                    }}
                  />
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    <span className="float-right">Subtotal</span>
                  </td>
                  <td>
                    <span className="d-flex flex-row justify-content-end">
                      <span className="mr-1">{selectedCurrency?.code}</span>
                      <span>{state?.subtotal?.toLocaleString()}</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <span className="float-right text-muted">Discount</span>
                  </td>
                  <td>
                    <span className="d-flex flex-row justify-content-end text-danger">
                      <span className="mr-1">- {selectedCurrency?.code}</span>
                      <span>{state.totalDiscount?.toLocaleString()}</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <span className="float-right">Grand Total</span>
                  </td>
                  <td>
                    <span className="d-flex flex-row justify-content-end">
                      <span className="mr-1">{selectedCurrency?.code}</span>
                      <span>{state.totalPrice?.toLocaleString()}</span>
                    </span>
                  </td>
                </tr>
              </tfoot>
            </Table>

            <ReferralCodeInput
              onAdd={addReferralCode}
              onRemove={deleteReferralCode}
              referralCode={selectedReferralCode?.code}
              isReferralCodeEnabled={isReferralCodeEnabled}
            />
          </CardBody>
        </Card>
      );
    }
    return null;
  };

  const TransactionPromos = () => {
    if (trxUser?.userData?.id) {
      return (
        <Card className="mb-2">
          <CardHeader className="d-flex justify-content-between">
            <div className="d-flex justify-content-between align-items-center">
              <b style={{ paddingInline: '10px', alignSelf: 'center' }}>
                Transaction Promos Applied
              </b>
              <FontAwesomeIcon
                data-toggle="tooltip"
                title="Promo Tidak Masuk Kalkulasi Hanya Untuk Pencatatan"
                icon={faExclamationCircle}
                style={{ cursor: 'pointer' }}
                size="lg"
              />
            </div>

            <LoadingButton
              style={{ marginLeft: '10px', minWidth: '113px' }}
              fullWidth={false}
              onClick={async () => {
                await fetchPromos();
                setAddTransactionPromosModal(true);
              }}
              isLoading={loadingFetchPromoList}
            >
              Add Promos
            </LoadingButton>
          </CardHeader>
          <CardBody>
            <Table hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Nominal</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.transactionPromos
                  ? state.transactionPromos.map((val, index) => {
                      return (
                        <tr>
                          <td>{val.name}</td>
                          <td>{val.unit}</td>
                          <td>
                            <NumberFormat
                              value={val.nominal}
                              thousandSeparator
                              disabled
                              style={{
                                backgroundColor: 'inherit',
                                border: '0px',
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                handleDeleteTransactionPromo(index)
                              }
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      );
    }
    return null;
  };

  return (
    <div className="container" style={{ paddingTop: '40px' }}>
      <div
        className="font-weight-bold mb-4"
        style={{ fontSize: '32px', color: '#505050' }}
      >
        Create Transaction
      </div>
      <div>
        <VerifyUser />
        <RenderItemList />

        {trxUser?.userData?.id && (
          <TransactionInstallment
            currency={selectedCurrency?.symbol}
            applyReferralCode={applyReferralCode}
            installmentFormRef={installmentFormRef}
          />
        )}

        <TransactionPromos />
        {trxUser?.userData?.id && (
          <DeadlinePicker
            selected={deadline}
            onChange={(date) => {
              setDeadline(date);
            }}
          />
        )}
        <LoadingButton
          isLoading={isLoading}
          onClick={createTransaction}
          style={{ width: '100%', marginBlock: '0.5rem' }}
        >
          Create
        </LoadingButton>
      </div>
      <AddProgramModal
        isOpen={addProgramModal}
        toggle={() => setAddProgramModal(!addProgramModal)}
        addTransactionItem={addTransactionItem}
        userData={trxUser.userData}
        selectedCurrency={selectedCurrency}
      />
      <AddSubscriptionModal
        isOpen={addSubscriptionModal}
        toggle={() => setAddSubscriptionModal(!addSubscriptionModal)}
        addTransactionItem={addTransactionItem}
        userData={trxUser.userData}
      />
      <AddServiceModal
        isOpen={addServiceModal}
        toggle={() => setAddServiceModal(!addServiceModal)}
        addTransactionItem={addTransactionItem}
        userData={trxUser.userData}
      />
      <AddOnlineCourseModal
        isOpen={addOnlineCourseModal}
        toggle={() => setAddOnlineCourseModal(!addOnlineCourseModal)}
        addTransactionItem={addTransactionItem}
        userData={trxUser.userData}
      />
      <AddAlumniEventModal
        isOpen={addAlumniEventModal}
        toggle={() => setAlumniEventModal(!addAlumniEventModal)}
        addTransactionItem={addTransactionItem}
        userData={trxUser.userData}
      />
      <AddTransactionPromosModal
        isOpen={addTransactionPromosModal}
        toggle={() => setAddTransactionPromosModal(!addTransactionPromosModal)}
        promoList={transactionPromosList}
        handleAddTransactionPromos={handleAddTransactionPromos}
      />
      <ApplyVoucherModal
        isOpen={applyVoucherModal.isOpen}
        toggle={() =>
          setApplyVoucherModal({
            ...applyVoucherModal,
            isOpen: !applyVoucherModal.isOpen,
          })
        }
        addVoucherCode={addVoucherCode}
      />

      {/* <ConfirmationModal
        isOpen={handleConfirmModal}
        toggle={() => setHandleConfirmModal(!handleConfirmModal)}
        message={
          state.transactionPromos.length ? (
            <>
              <div className="mb-3">
                Are you sure you want to continue using this Transaction Promo?
              </div>
              <Table>
                <thead>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Nominal</th>
                </thead>
                <tbody>
                  {state.transactionPromos?.map((promo) => (
                    <tr>
                      <td>{promo.name}</td>
                      <td>{promo.unit}</td>
                      <td>
                        <NumberFormat
                          value={promo.nominal}
                          thousandSeparator
                          disabled
                          style={{
                            backgroundColor: 'inherit',
                            border: '0px',
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            'Transaction Promo not applied. Are you sure you want to continue without Transaction Promo?'
          )
        }
      >
        <div className="w-25 d-flex justify-content-end ">
          <div>
            <LoadingButton isLoading={isLoading} onClick={createTransaction}>
              Continue
            </LoadingButton>
          </div>
          <Button
            onClick={() => setHandleConfirmModal(!handleConfirmModal)}
            className="ml-3 w-100 my-2"
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </ConfirmationModal> */}
    </div>
  );
};

export default withRouter(CreateTransactionPage);
