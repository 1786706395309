import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Button,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const ApplyVoucherModal = ({ isOpen, toggle, addVoucherCode }) => {
  return (
    <Formik
      initialValues={{ voucherCode: '' }}
      onSubmit={async (values, { resetForm }) => {
        await addVoucherCode(values.voucherCode);
        resetForm();
      }}
      enableReinitialize
    >
      {({ handleSubmit, resetForm, isSubmitting }) => (
        <Modal
          isOpen={isOpen}
          toggle={() => {
            toggle();
            resetForm();
          }}
        >
          <ModalHeader
            toggle={() => {
              toggle();
              resetForm();
            }}
          >
            Apply Voucher
          </ModalHeader>
          <ModalBody>
            <Form style={{ marginTop: '10px' }}>
              <InputGroup>
                <Field name="voucherCode" className="form-control" />
              </InputGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Enter
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};
export default ApplyVoucherModal;
