import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

export default ({ item, index, onDelete, onApplyVoucher, onDeleteVoucher }) => {
  const {
    productType,
    data,
    price,
    qty,
    description,
    currency,
    notes,
    voucherCode,
    voucherAmount,
    discount,
  } = item;

  let cardDesc = '';
  if (productType === 'program') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>
          {data?.code} || {data?.name}
        </div>
        <div className="font-weight-lighter" style={{ fontSize: '12px' }}>
          {`${moment(data?.startDate).format('DD MMM YYYY')} -
            ${moment(data?.endDate).format('DD MMM YYYY')}`}
        </div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${description}`}</div>
      </div>
    );
  }

  if (productType === 'service') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.name}</div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${description}`}</div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${notes}`}</div>
      </div>
    );
  }

  if (productType === 'online_course') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.title}</div>
      </div>
    );
  }

  if (productType === 'registrationFee') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>Registration Fee</div>
      </div>
    );
  }

  if (productType === 'subscription') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.name}</div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${data?.description}`}</div>
        <div className="font-weight-lighter" style={{ fontSize: '12px' }}>
          Notes : {`${notes}`}
        </div>
      </div>
    );
  }

  if (productType === 'alumni_events') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.title}</div>
        <div className="font-weight-lighter" style={{ fontSize: '12px' }}>
          Alumni Event
        </div>
      </div>
    );
  }

  return (
    <tr>
      <th width="10px">
        {productType !== 'registrationFee' && (
          <FontAwesomeIcon
            icon={['fas', 'times']}
            color="#495057"
            size="1x"
            className="cursor-pointer"
            style={{ width: '10px' }}
            onClick={() => onDelete(index)}
          />
        )}
      </th>
      <td colSpan={2}>
        <p> {cardDesc}</p>
        {voucherCode && (
          <div>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              VOUCHER CODE APPLIED:
            </div>{' '}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '12px' }}>{voucherCode}</div>
              <Button
                color="link"
                style={{ color: 'red', paddingBlock: 0 }}
                onClick={onDeleteVoucher}
              >
                remove
              </Button>
            </div>
          </div>
        )}
      </td>
      <td colSpan={1}>
        <div className="float-right">
          <span className="mr-1">{currency}</span>
          <span>{price?.toLocaleString()}</span>
        </div>
      </td>
      <td colSpan={1} width="10px">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ textAlign: 'end' }}>x{qty}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '16px',
            }}
          >
            {Boolean(item.discount) && (
              <div style={{ whiteSpace: 'nowrap' }}>Discount:</div>
            )}

            {Boolean(item.voucherCode) && (
              <div style={{ whiteSpace: 'nowrap' }}>Voucher:</div>
            )}
          </div>
        </div>
      </td>
      <td>
        <span className="d-flex flex-row justify-content-end">
          <span className="mr-1">{currency}</span>
          <span>{((price ?? 0) * (qty ?? 1)).toLocaleString()}</span>
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: '16px',
          }}
        >
          {Boolean(discount) && (
            <div className="d-flex flex-row justify-content-end">
              - {(discount * qty ?? 1)?.toLocaleString()}{' '}
            </div>
          )}
          {/* Apply voucher feature is only available for specific sales: program, online_course, or alumni_events */}
          {['program', 'online_course', 'alumni_events'].includes(
            productType,
          ) && (
            <div className="d-flex flex-row justify-content-end">
              {voucherAmount ? (
                <span>- {voucherAmount?.toLocaleString()}</span>
              ) : (
                <Button
                  onClick={() => onApplyVoucher()}
                  color="success"
                  outline
                >
                  Apply Voucher
                </Button>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
