import React, { Component } from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';
import axios from 'supports/api';
import Loader from 'react-loader-spinner';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';

const INITIAL_STATE = {
  banks: [],
  paymentMethods: [],
  tujuanpenerimaans: [],
  isLoading: false,
};

const INITIAL_FORM = {
  payment_methodId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please select payment method',
  },
  nominal: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    error: 'Please enter payment nominal',
  },
  mutationDate: {
    value: new Date(),
    valid: false,
    invalid: false,
    error: 'Please enter mutation date',
  },
  bankId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please select target bank',
  },
  proofObject: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please upload proof',
  },
};

class AddPaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_FORM,
    };
  }

  componentDidMount() {
    axios
      .get(`/bank/all`)
      .then((res) => {
        this.setState({ banks: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/transaction/payment-methods`)
      .then((res) => {
        this.setState({ paymentMethods: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  resetForm = () => {
    this.setState((prevState) => ({ ...prevState, ...INITIAL_FORM }));
  };

  handleInput = (state, value) => {
    const valid = true;
    const invalid = false;

    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value, valid, invalid },
    }));
  };

  handleSelect = (state, value) => {
    const formReset = {};
    let valid = false;
    let invalid = true;
    if (value !== 0) {
      valid = true;
      invalid = false;
    }

    this.setState((prevState) => ({
      ...prevState,
      ...formReset,
      [state]: { value, valid, invalid },
    }));
  };

  handleFile = (file) => {
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        proofObject: {
          ...prevState.proofObject,
          value: file,
          valid: true,
          invalid: false,
        },
      }));
    }
  };

  handleCurrencyInput = (state, value) => {
    let number = parseInt(value.split(',').join(''), 10);
    let displayValue = number.toLocaleString('en-US');

    if (value.split(',').join('') === '') {
      number = 0;
      displayValue = '0';
    }
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: number, displayValue },
    }));
  };

  validateForm = async (form) => {
    let result = 0;

    await Object.keys(form).forEach(async (obj) => {
      if (!form[obj].valid) {
        console.log(!form[obj].valid);
        await this.setState((prevState) => ({
          [obj]: { ...prevState[obj], invalid: true },
        }));
        result += result + 1;
      }
    });

    if (result > 0) {
      return false;
    }
    return true;
  };

  onAddPaymentClick = () => {
    const { nominal, bankId, payment_methodId, proofObject } = this.state;

    const validation = this.validateForm({
      nominal,
      bankId,
      payment_methodId,
      proofObject,
    }); // returns a promise

    validation.then((valid) => {
      if (valid) {
        this.setState((prevState) => ({ ...prevState, isLoading: true }));

        setTimeout(() => {
          const formData = new FormData();
          formData.append('nominal', nominal.value);
          formData.append('bankId', bankId.value);
          formData.append('payment_methodId', payment_methodId.value);
          formData.append('proof', proofObject.value);

          axios
            .post(`/admin/create/payment/${this.props.transactionId}`, formData)
            .then((res) => {
              this.resetForm();
              alert(res.data ? res.data.message : 'Create Payment Success');
              this.setState({ isLoading: false });
              this.props.refresh();
              this.props.toggle();
            })
            .catch((err) => {
              const { message, error } = err.response.data;
              alert(`${message}\n${error}`);
              this.setState({ isLoading: false });
            });
        }, 400); // Loads for a minimal of 0.4s
      } else {
        alert('There are invalid fields');
      }
    });
  };

  NominalInput = ({ displayValue, error }) => {
    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Nominal</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            {this.props.currency.symbol}
          </InputGroupAddon>
          <Input
            type="text"
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('nominal', target.value)
            }
          />
        </InputGroup>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  FilePicker = ({ value, valid, invalid, error }) => {
    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Bukti Pembayaran</div>
        <CustomInput
          id="filepicker"
          type="file"
          valid={valid}
          invalid={invalid}
          label={value ? value.name : null}
          onChange={({ target }) => this.handleFile(target.files[0])}
        />
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  //   MutationDateInput = ({ value, valid, invalid, error }) => {
  //     return (
  //       <div>
  //         <div className="text-gray font-weight-bold mb-2">Nominal</div>
  //         <InputGroup>
  //           <DatePicker
  //             className="form-control"
  //             selected={this.state.startDate}
  //             selectsStart
  //             startDate={this.state.startDate}
  //             endDate={this.state.endDate}
  //             dateFormat="dd/MM/yyy"
  //             onChange={this.handleStartDate}
  //             showMonthYearDropdown
  //             minDate={moment()
  //               .subtract(6, "months")
  //               .toDate()}
  //             maxDate={moment()
  //               .add(6, "months")
  //               .toDate()}
  //           />
  //           <Input
  //             type="text"
  //             onChange={({ target }) =>
  //               this.handleCurrencyInput("mutationDate", target.value)
  //             }
  //           />
  //         </InputGroup>
  //         <FormFeedback invalid="true">*{error}</FormFeedback>
  //       </div>
  //     );
  //   };

  RenderPaymentMethods = ({ value, valid, invalid, error }) => {
    const filterPaymentMethods = this.state.paymentMethods.filter(
      (paymentMethod) => paymentMethod.name !== 'Voucher',
    );

    const arrJSX = filterPaymentMethods.map((item) => {
      return (
        <option key={JSON.stringify(item)} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Payment Method</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('payment_methodId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Pilih Metode
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  RenderBanks = ({ value, valid, invalid, error }) => {
    const arrJSX = this.state.banks.map((item) => {
      return (
        <option key={JSON.stringify(item)} value={item.id}>
          {item.bankName} - {item.accountNumber}
        </option>
      );
    });
    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Bank</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => this.handleSelect('bankId', target.value)}
        >
          <option value={0} disabled hidden>
            Pilih Bank
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  CreateButton = ({ onClick, isLoading }) => {
    if (isLoading)
      return (
        <Button color="secondary" className="rounded-right w-100 my-2" disabled>
          <Loader type="Oval" color="white" height={18} width="100%" />
        </Button>
      );
    return (
      <Button
        color="success"
        className="rounded-right w-100 my-2"
        onClick={onClick}
        style={{ backgroundColor: '#53b59f' }}
      >
        Create
      </Button>
    );
  };

  render() {
    const { isOpen, toggle, className } = this.props;
    const {
      payment_methodId,
      bankId,
      nominal,
      proofObject,
      isLoading,
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        size="lg"
        onClosed={this.resetForm}
      >
        <ModalHeader>Add New Payment</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <this.RenderPaymentMethods {...payment_methodId} />
              <this.RenderBanks {...bankId} />
              <this.NominalInput {...nominal} />
              <this.FilePicker {...proofObject} />
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <this.CreateButton
            onClick={this.onAddPaymentClick}
            isLoading={isLoading}
          />
          {/* <Button color="success" onClick={this.onAddPaymentClick}  size="sm">Create</Button> */}
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddPaymentModal;
