import React, { Component } from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import axios from 'supports/api';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import GeneralTooltips from '../commons/GeneralTooltips';

const INITIAL_FORM = {
  prefix: '',
  nominal: '',
  nominalDisplayed: '0',
  count: '',
  validFrom: moment().startOf('date').toDate(),
  validTo: moment().startOf('date').toDate(),
  vendor: '',
};

class CreateVoucherModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_FORM,
    };
  }

  onCreateVouchers = async () => {
    // await this.checkInput()
    // if (this.props.access === true) {

    // };

    const data = {
      prefix: this.state.prefix,
      nominal: this.state.nominal,
      vendor: this.state.vendor,
      count: this.state.count,
      validFrom: this.state.validFrom,
      validTo: this.state.validTo,
    };

    axios
      .post(`/admin/create/vouchers`, data)
      .then((res) => {
        alert('Membuat Voucher Success');
        this.resetFields();
        this.props.refresh();
        this.props.toggle();
      })
      .catch((err) => {
        const { message, error } = err.response.data;
        alert(`${message}\n${error}`);
        console.log(err);
      });
  };

  checkInput = async () => {
    const tempArr = this.props.tooltip.slice();
    let access = true;
    this.state.nominal === 0 || this.state.nominal.length === 0
      ? (function () {
          tempArr[0] = true;
          access = false;
        })()
      : (function () {
          tempArr[0] = false;
          access = true;
        })();
    this.state.vendor.length === 0
      ? (function () {
          tempArr[1] = true;
          access = false;
        })()
      : (function () {
          tempArr[0] = false;
          access = true;
        })();
    this.state.count.length === 0
      ? (function () {
          tempArr[2] = true;
          access = false;
        })()
      : (function () {
          tempArr[0] = false;
          access = true;
        })();
    this.state.prefix.length === 0
      ? (function () {
          tempArr[3] = true;
          access = false;
        })()
      : (function () {
          tempArr[0] = false;
          access = true;
        })();
    await this.props.evalAccess(tempArr, access);
  };

  resetFields = () => {
    this.setState({ ...INITIAL_FORM });
  };

  handleText = (state, text) => {
    this.setState({ [state]: text });
  };

  handleNumber = (value) => {
    let number = parseInt(value.split(',').join(''), 10);
    let displayValue = number.toLocaleString('en-US');

    if (value.split(',').join('') === '') {
      number = 0;
      displayValue = '0';
    }

    this.setState({
      nominalDisplayed: displayValue,
      nominal: number,
    });
  };

  allowNumbersOnly = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  };

  handleValidFrom = (date) => {
    const startOfDate = moment(date).startOf('date').toDate();
    if (this.state.validTo < startOfDate) {
      this.setState({
        validFrom: startOfDate,
        validTo: startOfDate,
      });
    } else {
      this.setState({
        validFrom: startOfDate,
      });
    }
  };

  handleValidTo = (date) => {
    const startOfDate = moment(date).startOf('date').toDate();
    if (this.state.validFrom > startOfDate) {
      this.setState({
        validFrom: startOfDate,
        validTo: startOfDate,
      });
    } else {
      this.setState({
        validTo: startOfDate,
      });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
        unmountOnClose
        onClosed={this.resetFields}
      >
        <ModalHeader>Generate Vouchers</ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">
                  Nominal:{' '}
                </div>
                <InputGroup id="nominal">
                  <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
                  <input
                    type="text"
                    className="form-control"
                    id="newVoucherNominal"
                    placeholder="100,000"
                    value={this.state.nominalDisplayed}
                    onKeyPress={this.allowNumbersOnly}
                    onChange={(e) => this.handleNumber(e.target.value)}
                  />
                </InputGroup>
                <GeneralTooltips
                  position="bottom"
                  toggle={this.props.tooltip[0]}
                  id="nominal"
                  content="masukkan nominal voucher"
                />
              </div>
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">
                  Vendor:{' '}
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="newVoucherVendor"
                  placeholder="tokped"
                  value={this.state.vendor}
                  onChange={(e) => this.handleText('vendor', e.target.value)}
                />
                <GeneralTooltips
                  position="bottom"
                  toggle={this.props.tooltip[1]}
                  id="newVoucherVendor"
                  content="masukkan vendor"
                />
              </div>
            </div>
            <div className="row">
              <div className="py-3 col-2">
                <div className="tag-gray dker font-weight-bold mb-3">
                  Valid From :
                </div>
                <DatePicker
                  className="form-control"
                  selected={this.state.validFrom}
                  selectsStart
                  startDate={this.state.validFrom}
                  endDate={this.state.validTo}
                  dateFormat="dd/MM/yyy"
                  onChange={this.handleValidFrom}
                  showMonthYearDropdown
                  minDate={moment().subtract(12, 'months').toDate()}
                  maxDate={moment().add(12, 'months').toDate()}
                />
              </div>
              <div className="py-3 col-2">
                <div className="tag-gray dker font-weight-bold mb-3">
                  ValidTo:
                </div>
                <DatePicker
                  className="form-control"
                  selected={this.state.validTo}
                  selectsEnd
                  startDate={this.state.validFrom}
                  endDate={this.state.validTo}
                  dateFormat="dd/MM/yyy"
                  onChange={this.handleValidTo}
                  showMonthYearDropdown
                  minDate={moment().subtract(12, 'months').toDate()}
                  maxDate={moment().add(12, 'months').toDate()}
                />
              </div>
              <div className="py-3 offset-2 col-6">
                <div className="tag-gray dker font-weight-bold mb-3">
                  Jumlah Voucher:{' '}
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="newVoucherCount"
                  placeholder="1xx"
                  value={this.state.count}
                  onChange={(e) => this.handleText('count', e.target.value)}
                />
                <GeneralTooltips
                  position="bottom"
                  toggle={this.props.tooltip[2]}
                  id="newVoucherCount"
                  content="masukkan jumlah voucher"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">
                  Prefix:{' '}
                </div>
                <InputGroup>
                  <input
                    type="text"
                    className="form-control"
                    id="newVoucherPrefix"
                    placeholder="contoh: PRW"
                    value={this.state.prefix}
                    onChange={(e) => this.handleText('prefix', e.target.value)}
                  />
                  <GeneralTooltips
                    position="bottom"
                    toggle={this.props.tooltip[3]}
                    id="newVoucherPrefix"
                    content="masukkan prefix"
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.onCreateVouchers}>
            Create
          </Button>
          <Button color="danger" onClick={this.props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateVoucherModal;
