import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
  Badge,
  Alert,
} from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkRole } from 'supports/helpers/permissions';
import NumberFormat from 'react-number-format';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import PaymentCard from './PaymentCard';
import AddPaymentModal from './AddPaymentModal';
import InputVoucherModal from './InputVoucherModal';
import PaymentList from './PaymentList';
import TransactionInstallmentPaymentTable from './create-transaction/components/TransactionInstallment/components/TransactionInstallmentPayment/components/TransactionInstallmentPaymentTable';
import TransactionInstallmentPaymentForm from './create-transaction/components/TransactionInstallment/components/TransactionInstallmentPayment/components/TransactionInstallmentPaymentForm';

class TransactionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: {
        productItem: { product: {}, branch: {} },
        payments: [],
        transactionItems: [],
        user: {},
        subtotal: 0,
        totalDiscount: 0,
        totalPrice: 0,
        accountsReceivable: 0,
        referralCodeId: 0,
        referral_code: {},
        transactionPromoUsages: [],
        totalVoucherDiscount: 0,
      },
      paid: [],
      modal: false,
      addPaymentModal: false,
      inputVoucherModal: false,
      invoiceLoading: false,
      orderLoading: false,
    };
  }

  componentDidMount() {
    // var render = checkForm(this.props.auth.access, "Payment Confirmation")
    // if(render){
    this.getData();
    // }
  }

  getData = () => {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    axios
      .get(`/admin/transaction-details/${id}`)
      .then((res) => {
        this.setState({ transaction: res.data.result });
      })
      .catch((err) => {
        alert(err.response.data.error);
        this.props.history.push('transactions');
      });
  };

  toggleModal = () => {
    if (
      this.state.transaction.status === 'paid' &&
      this.state.transaction.accountsReceivable === 0
    ) {
      alert('Transaksi sudah lunas');
    } else {
      this.setState((prevState) => ({ modal: !prevState.modal }));
    }
  };

  toggleModalVoucher = () => {
    if (
      this.state.transaction.status === 'paid' &&
      this.state.transaction.accountsReceivable === 0
    ) {
      alert('Transaksi sudah lunas');
    } else {
      this.setState((prevState) => ({
        inputVoucherModal: !prevState.inputVoucherModal,
      }));
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  RenderItemList = () => {
    let subTotal = 0;
    // let totalDiscount = 0
    let registrationFeePrice = 0;
    let registrationFeeQty = 0;
    let totalPrice = 0;

    const arrJSX = [];
    const RenderDate = ({ startDate, endDate }) => {
      // If same date
      if (moment(startDate).isSame(endDate)) {
        return moment(startDate).format('DD MMM YYYY');
      }

      // checks if different year
      return moment(startDate).isSame(endDate, 'year')
        ? `${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
            'DD MMM YYYY',
          )}`
        : `${moment(startDate).format('DD MMM YYYY')} - ${moment(
            endDate,
          ).format('DD MMM YYYY')}`;
    };

    const {
      programSales,
      serviceSales,
      onlineCourseSales,
      subscriptionSales,
      alumniEventSales,
      currency,
      referral_code,
      referralCodeId,
    } = this.state.transaction;

    let totalVoucherDiscount = 0;

    if (programSales) {
      programSales.forEach((item) => {
        const nominal = (item?.price ?? 0) * (item?.qty ?? 1);
        subTotal += nominal;

        totalVoucherDiscount += item?.voucherApplication?.appliedDiscount;

        const itemDescription = (
          <div className="d-flex flex-column">
            <div>
              [{item.code}] {item.name}
            </div>
            <div>
              <RenderDate startDate={item.startDate} endDate={item.endDate} />
            </div>
            {/* <div className="text-muted font-weight-lighter">{`Attendee: ${item.attendeeName}`}</div> */}
            {item.description && (
              <div className="mt-1">({item.description})</div>
            )}
          </div>
        );
        arrJSX.push(
          <tr key={JSON.stringify(item)}>
            <td colSpan={3}>
              {itemDescription}{' '}
              {item?.voucherApplication && (
                <span className="d-flex flex-row mt-1">
                  <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                    Voucher Applied:{' '}
                  </span>
                  <span> {item?.voucherApplication?.voucher?.number}</span>
                </span>
              )}
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{item?.price?.toLocaleString() ?? 0}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span>x{item?.qty ?? 1}</span>
              </span>
              {item?.voucherApplication && (
                <span className="d-flex flex-row justify-content-end mt-1">
                  <span className="text-muted">Voucher:</span>
                </span>
              )}
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{nominal?.toLocaleString() ?? 0}</span>
              </span>
              {item?.voucherApplication && (
                <span className="d-flex flex-row justify-content-end mt-1 text-danger">
                  <span className="mr-1">-{currency?.code}</span>
                  <span>
                    {item?.voucherApplication?.appliedDiscount?.toLocaleString() ??
                      0}
                  </span>
                </span>
              )}
            </td>
          </tr>,
        );
        if (item.registrationFee) {
          registrationFeePrice = item.registrationFee;
          registrationFeeQty += item?.qty;
          const totalRegistrationFee =
            (item?.registrationFee ?? 0) * (item?.qty ?? 1);
          subTotal += totalRegistrationFee;
          // const registrationFeeDescription = (
          //   <div className="d-flex flex-column">
          //     <div className="text-muted font-weight-lighter">
          //       Registration Fee
          //     </div>
          //   </div>
          // );
          // arrJSX.push(
          //   <tr key={`RegistrationFee${JSON.stringify(item)}`}>
          //     <td colSpan={3}>{registrationFeeDescription}</td>
          //     <td>
          //       <span className="d-flex flex-row justify-content-end">
          //         <span className="mr-1">IDR</span>
          //         <span>
          //           {item.registrationFee
          //             ? item.registrationFee.toLocaleString()
          //             : 0}
          //         </span>
          //       </span>
          //     </td>
          //   </tr>,
          // );
        }
      });
    }

    if (serviceSales) {
      serviceSales.forEach((item) => {
        const nominal = item?.price ?? 0;
        subTotal += nominal;
        // totalDiscount += item.discount ? item.discount : 0
        const itemDescription = (
          <div className="d-flex flex-column">
            <div>{item.name}</div>
            <div className="text-muted font-weight-lighter">
              {item.description}
            </div>
            <div className="text-muted font-weight-lighter">{item.notes}</div>
          </div>
        );
        arrJSX.push(
          <tr key={JSON.stringify(item)}>
            <td colSpan={3}>{itemDescription}</td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{item?.price?.toLocaleString() ?? 0}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span>x{item?.qty ?? 1}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{nominal?.toLocaleString() ?? 0}</span>
              </span>
            </td>
          </tr>,
        );
      });
    }

    if (onlineCourseSales) {
      onlineCourseSales.forEach((item) => {
        const nominal = item?.price ?? 0;
        subTotal += nominal;

        totalVoucherDiscount += item?.voucherApplication?.appliedDiscount;

        // totalDiscount += item.discount ? item.discount : 0
        const itemDescription = (
          <div className="d-flex flex-column">
            <div>{item.name}</div>
            <div className="text-muted font-weight-lighter">
              {item.description}
            </div>
          </div>
        );
        arrJSX.push(
          <tr key={JSON.stringify(item)}>
            <td colSpan={3}>
              {itemDescription}{' '}
              {item?.voucherApplication && (
                <span className="d-flex flex-row mt-1">
                  <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                    Voucher Applied:{' '}
                  </span>
                  <span> {item?.voucherApplication?.voucher?.number}</span>
                </span>
              )}
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{item?.price?.toLocaleString() ?? 0}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span>x{item?.qty ?? 1}</span>
              </span>
              {item?.voucherApplication && (
                <span className="d-flex flex-row justify-content-end mt-1">
                  <span className="text-muted">Voucher:</span>
                </span>
              )}
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{nominal?.toLocaleString() ?? 0}</span>
              </span>
              {item?.voucherApplication && (
                <span className="d-flex flex-row justify-content-end mt-1 text-danger">
                  <span className="mr-1">-{currency?.code}</span>
                  <span>
                    {item?.voucherApplication?.appliedDiscount?.toLocaleString() ??
                      0}
                  </span>
                </span>
              )}
            </td>
          </tr>,
        );
      });
    }

    if (subscriptionSales) {
      subscriptionSales.forEach((item) => {
        const nominal = item?.price ?? 0;

        subTotal += nominal;

        const itemDescription = (
          <div className="d-flex flex-column">
            <div>{item.name}</div>
            <div className="text-muted font-weight-lighter">
              {item.description}
            </div>
            <div className="text-muted font-weight-lighter">
              Notes : {item.notes}
            </div>
          </div>
        );
        arrJSX.push(
          <tr key={JSON.stringify(item)}>
            <td colSpan={3}>{itemDescription}</td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{item?.price?.toLocaleString() ?? 0}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span>x{item?.qty ?? 1}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{nominal?.toLocaleString() ?? 0}</span>
              </span>
            </td>
          </tr>,
        );
      });
    }

    if (alumniEventSales) {
      alumniEventSales.forEach((item) => {
        const nominal = item?.price ?? 0;

        subTotal += nominal;

        totalVoucherDiscount += item?.voucherApplication?.appliedDiscount;

        arrJSX.push(
          <tr key={JSON.stringify(item)}>
            <td colSpan={3}>
              {item?.name}
              {item?.voucherApplication && (
                <span className="d-flex flex-row mt-1">
                  <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                    Voucher Applied:{' '}
                  </span>
                  <span> {item?.voucherApplication?.voucher?.number}</span>
                </span>
              )}
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{item?.price?.toLocaleString() ?? 0}</span>
              </span>
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span>x{item?.qty ?? 1}</span>
              </span>
              {item?.voucherApplication && (
                <span className="d-flex flex-row justify-content-end mt-1">
                  <span className="text-muted">Voucher:</span>
                </span>
              )}
            </td>
            <td>
              <span className="d-flex flex-row justify-content-end">
                <span className="mr-1">{currency?.code}</span>
                <span>{nominal?.toLocaleString() ?? 0}</span>
              </span>
              {item?.voucherApplication && (
                <span className="d-flex flex-row justify-content-end mt-1 text-danger">
                  <span className="mr-1">-{currency?.code}</span>
                  <span>
                    {item?.voucherApplication?.appliedDiscount?.toLocaleString() ??
                      0}
                  </span>
                </span>
              )}
            </td>
          </tr>,
        );
      });
    }

    if (registrationFeeQty > 0 || registrationFeePrice > 0) {
      arrJSX.push(
        <tr key="registrationFee">
          <td colSpan={3}>Registration Fee</td>
          <td>
            <span className="d-flex flex-row justify-content-end">
              <span className="mr-1">{currency?.code}</span>
              <span>{registrationFeePrice?.toLocaleString() ?? 0}</span>
            </span>
          </td>
          <td>
            <span className="d-flex flex-row justify-content-end">
              <span>x{registrationFeeQty}</span>
            </span>
          </td>
          <td>
            <span className="d-flex flex-row justify-content-end">
              <span className="mr-1">{currency?.code}</span>
              <span>
                {(registrationFeePrice * registrationFeeQty)?.toLocaleString()}
              </span>
            </span>
          </td>
        </tr>,
      );
    }

    totalPrice = subTotal - this.state.transaction.totalDiscount;

    const { nominal: referralCodeNominal, isPercentage } = referral_code || {};

    let referralCodeDiscount = 0;

    if (referralCodeId) {
      if (isPercentage) {
        const calcPercentageDisc =
          (referralCodeNominal / 100) * (subTotal - totalVoucherDiscount);

        referralCodeDiscount += calcPercentageDisc;
      } else {
        referralCodeDiscount += Number(referralCodeNominal);
      }
    }

    return (
      <Card>
        <CardHeader>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="my-auto d-flex flex-row font-weight-bold">
              Item List
            </div>
            <div className="d-flex flex-row flex-wrap" />
          </div>
        </CardHeader>
        <CardBody>
          <Alert
            color="danger"
            className=""
            isOpen={
              !(programSales || []).length &&
              !(onlineCourseSales || []).length &&
              !(serviceSales || []).length &&
              !(subscriptionSales || []).length &&
              !(alumniEventSales || []).length
            }
          >
            No items in list!
          </Alert>
          <Alert
            color="info"
            className=""
            isOpen={this.state.transaction?.isDeposit}
          >
            This is a Deposit Transaction!
          </Alert>
          <Table hover>
            <thead>
              <tr>
                <th colSpan={3}>Description</th>
                <th>
                  <span className="float-right">Price</span>
                </th>
                <th>
                  <span className="float-right">Qty</span>
                </th>
                <th>
                  <span className="float-right">Nominal</span>
                </th>
              </tr>
            </thead>
            <tbody>{arrJSX}</tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>
                  <span className="float-right">Subtotal</span>
                </td>
                <td>
                  <span className="d-flex flex-row justify-content-end">
                    <span className="mr-1">{currency?.code}</span>
                    <span>{subTotal.toLocaleString()}</span>
                  </span>
                </td>
              </tr>

              {referralCodeId && (
                <tr>
                  <td colSpan={5}>
                    <span className="float-right text-muted">
                      Referral Code Discount
                    </span>
                  </td>
                  <td>
                    <span className="d-flex flex-row justify-content-end text-danger">
                      <span className="mr-1">- {currency?.code}</span>
                      <span>{referralCodeDiscount.toLocaleString()}</span>
                    </span>
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan={5}>
                  <span className="float-right text-muted">Total Discount</span>
                </td>
                <td>
                  <span className="d-flex flex-row justify-content-end text-danger">
                    <span className="mr-1">- {currency?.code}</span>
                    <span>
                      {this.state.transaction.totalDiscount.toLocaleString()}
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <span className="float-right">Grand Total</span>
                </td>
                <td>
                  <span className="d-flex flex-row justify-content-end">
                    <span className="mr-1">{currency?.code}</span>
                    <span>{totalPrice.toLocaleString()}</span>
                  </span>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    );
  };

  CustomerDetails = ({ profilePicture, firstName, lastName, email, phone }) => {
    return (
      <Card className="my-1">
        <CardHeader className="font-weight-bold">Customer Details</CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12 font-sm-10 font-md-12">
              <div className="d-inline-flex flex-column flex-sm-column flex-md-row">
                <div className="d-flex flex-row py-1">
                  <img
                    src={process.env.REACT_APP_BASE_URL + profilePicture}
                    alt=""
                    style={{ height: 100, width: 100 }}
                    className="mr-3 border"
                  />
                </div>
                <div className="d-flex flex-column flex-sm-column flex-md-row py-1">
                  <div className="pr-5">
                    <div className="font-weight-bold text-gray">Name</div>
                    <div className="pb-1">
                      {firstName} {lastName}
                    </div>
                    <div className="font-weight-bold text-gray">Email</div>
                    <div className="pb-1">{email}</div>
                  </div>
                  <div className="pr-5">
                    <div className="font-weight-bold text-gray">Phone</div>
                    <div className="pb-1">{phone}</div>
                    <div className="font-weight-bold text-gray">Membership</div>
                    <div className="pb-1">None</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  handleGenerateInvoice = async (transactionId) => {
    if (window.confirm('Apakah anda yakin ingin membuat invoice number?')) {
      try {
        await axios.post(`/v2/transaction/force-create-invoice`, {
          transactionId,
        });
        this.getData();
      } catch (err) {
        console.log(err);
        alert(err);
      }
    }
  };

  TransactionDetails = () => {
    const onDownloadClick = (pdf) => {
      if (pdf === 'invoice' && !this.state.transaction?.invoiceNumber) {
        return window.alert('Invoice Not Available.');
      }
      if (
        pdf === 'order-number' &&
        !this.state.transaction?.orderConfirmationNumber
      ) {
        return window.alert('Order Confirmation Not Available.');
      }
      if (pdf === 'invoice') {
        this.setState({ invoiceLoading: true });
      } else {
        this.setState({ orderLoading: true });
      }
      return axios
        .get(`/admin/print/${pdf}/${this.state.transaction.id}`)
        .then(() => {
          if (pdf === 'invoice') {
            this.setState({ invoiceLoading: false });
          } else {
            this.setState({ orderLoading: false });
          }
          window.open(
            `${process.env.REACT_APP_BASE_URL}/admin/print/${pdf}/${this.state.transaction.id}`,
          );
        })
        .catch((err) => {
          if (pdf === 'invoice') {
            this.setState({ invoiceLoading: false });
          } else {
            this.setState({ orderLoading: false });
          }
          alert(err);
          console.log(err);
        });
    };

    const { transaction } = this.state;
    const { currency } = transaction || {};

    return (
      <Card className="my-1">
        <CardHeader className="font-weight-bold">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div
              className="my-auto d-flex flex-row font-weight-bold"
              style={{ gap: '16px' }}
            >
              Transaction Summary
              <div className="font-weight-bold text-gray">Status</div>
              <Badge>{transaction.status}</Badge>
            </div>
            <div className="d-flex flex-row flex-wrap">
              <div>
                {!transaction?.invoiceNumber &&
                  (checkRole(this.props.auth.access, 'super admin') ||
                    checkRole(
                      this.props.auth.access,
                      'finance department',
                    )) && (
                    <Button
                      onClick={() => this.handleGenerateInvoice(transaction.id)}
                      className="py-1 mr-2"
                      size="sm"
                    >
                      GENERATE INVOICE
                    </Button>
                  )}

                <Button
                  onClick={() => onDownloadClick('order-number')}
                  className="py-1 mr-2"
                  size="sm"
                >
                  <span style={{ color: 'white' }}>
                    {this.state.orderLoading ? (
                      <div
                        className="spinner-border spinner-border-sm mr-2 text-center"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <FontAwesomeIcon
                        className="mr-2 text-center"
                        icon={['fas', 'file-download']}
                        size="1x"
                      />
                    )}
                    ORDER
                  </span>
                </Button>
                <Button
                  onClick={() => onDownloadClick('invoice')}
                  className="py-1"
                  size="sm"
                >
                  <span style={{ color: 'white' }}>
                    {this.state.invoiceLoading ? (
                      <div
                        className="spinner-border spinner-border-sm mr-2 text-center"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <FontAwesomeIcon
                        className="mr-2 text-center"
                        icon={['fas', 'file-download']}
                        size="1x"
                      />
                    )}
                    INVOICE
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12 font-sm-10 font-md-12">
              <div className="d-flex flex-column flex-sm-column justify-content-md-between flex-md-row py-1">
                <div>
                  <div className="font-weight-bold text-gray">
                    Order Confirmation
                  </div>
                  <div className="pb-1">
                    {transaction.orderConfirmationNumber || 'N/A'}
                  </div>
                  <div className="font-weight-bold text-gray">Date</div>
                  <div className="pb-1">
                    {moment(transaction.createdAt).format('YYYY-MM-DD / HH:MM')}
                  </div>
                </div>
                <div>
                  <div className="font-weight-bold text-gray">Invoice</div>
                  <div className="pb-1">
                    {transaction.invoiceNumber || 'N/A'}
                  </div>
                  <div className="font-weight-bold text-gray">Total Price</div>
                  <div className="pb-1">
                    {`${
                      currency?.code
                    } ${transaction.totalPrice.toLocaleString()}`}
                  </div>
                </div>
                <div>
                  <div className="font-weight-bold text-gray">
                    Jumlah Terbayar
                  </div>
                  <div className="pb-1">
                    {`${currency?.code} ${(
                      transaction.totalPrice - transaction.accountsReceivable
                    ).toLocaleString()}`}
                  </div>
                  <div className="font-weight-bold text-gray">Sisa Tagihan</div>
                  <div className="pb-1">
                    {`${
                      currency?.code
                    } ${transaction.accountsReceivable.toLocaleString()}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  TransactionInstallmentInfo = () => {
    const { transaction } = this.state;
    const {
      dpTransactionId,
      dpTransactionTotalPrice,
      dpTransactionInvoiceNumber,
      dpTransactionOrderConfirmationNumber,
      currency,
      installmentType,
      installmentPrice,
      installmentTenure,
      installmentDiscount,
      installmentInterest,
      installmentTuitionFee,
      installmentDownPayment,
      transactionInstallment,
    } = transaction || {};

    const { transactionInstallmentPayments } = transactionInstallment || {};

    let totalNominalPayment = 0;

    // Adjust installment payments data
    const formatedInstallmentPayments = transactionInstallmentPayments?.map(
      (installmentPayment) => {
        totalNominalPayment += installmentPayment.price;

        return {
          id: installmentPayment.id,
          date: installmentPayment.date,
          paidAt: installmentPayment.paidAt,
          status: installmentPayment?.transactionStatus?.name,
          nominal: installmentPayment?.price,
          transactionId: installmentPayment.transactionId,
          orderConfirmationNumber:
            installmentPayment?.transaction?.orderConfirmationNumber,

          // Additional value
          isSaved: true,
          readOnly: true,
          allowEdit: false,
          originalValue: {},
        };
      },
    );

    return (
      <Card className="my-1">
        <CardHeader className="font-weight-bold">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="my-auto d-flex flex-row font-weight-bold">
              Informasi Cicilan
            </div>
          </div>
        </CardHeader>

        <CardBody>
          {/* Biaya Pendidikan */}
          <>
            <div className="font-weight-bold text-gray">Biaya Pendidikan</div>
            <div className="mb-4">
              {`${currency?.code} ${(
                installmentTuitionFee || 0
              ).toLocaleString()}`}
            </div>
          </>

          {/* Diskon */}
          <>
            <div className="font-weight-bold text-gray">Diskon</div>
            <div className="mb-4 text-danger">
              {`-${currency?.code} ${(
                installmentDiscount || 0
              ).toLocaleString()}`}
            </div>
          </>

          {/* Bunga */}
          <>
            <div className="font-weight-bold text-gray">Bunga</div>
            <div className="mb-4">
              {`${currency?.code} ${(
                installmentInterest || 0
              ).toLocaleString()}`}
            </div>
          </>

          {/* Tipe Cicilan & Tenor Cicilan */}
          <>
            <div className="font-weight-bold text-gray">
              Tipe Cicilan | Tenor Cicilan
            </div>
            <div className="mb-4">
              {installmentType || '-'} | {installmentTenure || '-'}
            </div>
          </>

          {/* DP */}
          <div className="mb-4">
            <div className="font-weight-bold text-gray">DP</div>
            <div className="mb-1">
              {`${currency?.code} ${(
                installmentDownPayment || 0
              ).toLocaleString()}`}
            </div>

            {/* Detail DP */}
            {Boolean(dpTransactionId) && (
              <>
                <div className="text-muted" style={{ fontSize: '14px' }}>
                  DP Transaksi
                </div>

                <div className="d-flex flex-row text-muted">
                  <div className="d-flex flex-column mr-1">
                    <div style={{ fontSize: '14px' }}>
                      Order Confirmation Number
                    </div>

                    <div style={{ fontSize: '14px' }}>Invoice Number</div>

                    <div style={{ fontSize: '14px' }}>Nominal</div>
                  </div>

                  <div className="d-flex flex-column">
                    <div style={{ fontSize: '14px' }}>
                      : {dpTransactionOrderConfirmationNumber || '-'}
                    </div>

                    <div style={{ fontSize: '14px' }}>
                      : {dpTransactionInvoiceNumber || '-'}
                    </div>

                    <div style={{ fontSize: '14px' }}>
                      :{' '}
                      {`${currency?.code} ${(
                        dpTransactionTotalPrice || 0
                      ).toLocaleString()}`}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Hutang */}
          <>
            <div className="font-weight-bold text-gray">Hutang</div>
            <div className="mb-4">
              {`${currency?.code} ${(installmentPrice || 0).toLocaleString()}`}
            </div>
          </>

          {/* Transaction Installment Payment Detail */}
          <div>
            <div className="mb-2 text-bold">
              Total Nominal Pembayaran : Rp{' '}
              {totalNominalPayment.toLocaleString()}
            </div>

            <Formik
              initialValues={{
                installmentPayments: formatedInstallmentPayments || [],
              }}
            >
              <TransactionInstallmentPaymentTable>
                <tbody>
                  <TransactionInstallmentPaymentForm />
                </tbody>
              </TransactionInstallmentPaymentTable>
            </Formik>
          </div>
        </CardBody>
      </Card>
    );
  };

  ReferralCodeInfo = () => {
    return (
      <Card className="my-1">
        <CardHeader className="font-weight-bold">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="my-auto d-flex flex-row font-weight-bold">
              Referral Code Applied
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <>
            <div className="font-weight-bold text-gray">Referral Code</div>
            <div className="mb-4">
              {this.state.transaction.referral_code.code}
            </div>
          </>
          <>
            <div className="font-weight-bold text-gray">Referral Name</div>
            <div className="mb-4">
              {this.state.transaction.referral_code.name}
            </div>
          </>
          <>
            <div className="font-weight-bold text-gray">
              Referral Applicant Type
            </div>
            <div className="mb-4">
              {this.state.transaction.referral_code.applicantType}
            </div>
          </>
          <>
            <div className="font-weight-bold text-gray">Referral Nominal</div>
            <div className="mb-4">
              {this.state.transaction.referral_code.isPercentage
                ? `${Number(this.state.transaction.referral_code.nominal)}%`
                : `${this.state.transaction?.currency?.code} ${(
                    Number(this.state.transaction.referral_code.nominal) || 0
                  ).toLocaleString()}`}{' '}
            </div>
          </>
        </CardBody>
      </Card>
    );
  };

  PaymentList = () => {
    return (
      <Card className="my-1">
        <CardHeader className="font-weight-bold">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="my-auto d-flex flex-row font-weight-bold">
              Payments
            </div>
            <div className="d-flex flex-row flex-wrap">
              <Button
                onClick={() => this.toggleModal('addPaymentModal')}
                className="py-1"
              >
                +Create
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="font-sm-10 font-md-12">
                <th>Receipt#</th>
                <th>Details</th>
                <th>Tanggal Mutasi</th>
                <th>Bukti</th>
                <th width="5%">Confirmed</th>
                {/* <th>Printed</th> */}
                <th>Cair</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <PaymentList
                currency={this.state.transaction.currency}
                payments={this.state.transaction.payments}
                transactionId={this.state.transaction.id}
                accountsReceivable={this.state.transaction.accountsReceivable}
                refresh={this.getData}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  TransactionPromoList = () => {
    return (
      <Card className="my-1">
        <CardHeader className="font-weight-bold">
          <div className="d-flex justify-content-between">
            <b>
              Transaction Promos Applied
              <span>
                <FontAwesomeIcon
                  data-toggle="tooltip"
                  title="Promo Tidak Masuk Kalkulasi Hanya Untuk Pencatatan"
                  icon={faExclamationCircle}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                  size="lg"
                />
              </span>
            </b>
          </div>
        </CardHeader>
        <CardBody>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="font-sm-10 font-md-12">
                <th>Nama</th>
                <th>Unit</th>
                <th>Nominal</th>
              </tr>
            </thead>
            <tbody>
              {this.state.transaction.transactionPromoUsages.length > 0
                ? this.state.transaction.transactionPromoUsages.map((val) => {
                    return (
                      <tr>
                        <td>{val.name}</td>
                        <td>{val.unit}</td>
                        <td>
                          <NumberFormat
                            value={val.nominal}
                            thousandSeparator
                            disabled
                            style={{
                              backgroundColor: 'inherit',
                              border: '0px',
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  renderPayments = () => {
    const arrJSX = this.state.transaction.payments.map((item, index) => {
      return (
        <PaymentCard
          key={JSON.stringify(item)}
          {...item}
          index={index}
          refresh={this.getData}
          accountsReceivable={this.state.transaction.accountsReceivable}
          transactionId={this.state.transaction.id}
        />
      );
    });

    return arrJSX;
  };

  toggleModal = (modalState) => {
    this.setState((prevState) => ({ [modalState]: !prevState[modalState] }));
  };

  render() {
    // const render = checkForm(this.props.auth.access, 'Payment Confirmation');
    // if(render){
    return (
      <div className="white-box py-2 container-fluid">
        <div className="row py-4">
          <div className="offset-0 col-12 offset-md-1 col-md-10">
            <div>
              <div className="line-green mb-4" />
              <div className="general-title text-gray">
                Transaction Details
                <Button
                  className="float-right"
                  onClick={() => this.handleBack()}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-12 offset-md-1 col-md-10">
            <div className="row">
              <div className="col-12 col-sm">
                <this.CustomerDetails {...this.state.transaction.user} />
              </div>
              <div className="col-12 col-sm">
                <this.TransactionDetails />
              </div>
            </div>
            <this.RenderItemList />
            {this.state.transaction.referralCodeId && <this.ReferralCodeInfo />}
            {this.state.transaction.isInstallment && (
              <this.TransactionInstallmentInfo />
            )}
            <this.PaymentList />
            <this.TransactionPromoList />
          </div>
        </div>
        <InputVoucherModal
          isOpen={this.state.inputVoucherModal}
          toggle={() => this.toggleModalVoucher()}
          transactionId={this.state.transaction.id}
        />
        <AddPaymentModal
          currency={this.state.transaction.currency}
          isOpen={this.state.addPaymentModal}
          toggle={() => this.toggleModal('addPaymentModal')}
          transactionId={this.state.transaction.id}
          refresh={this.getData}
        />
      </div>
    );
    // }
    // else return <Redirect to={"/"}/>
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return { auth };
};

export default withRouter(connect(mapStateToProps, {})(TransactionDetails));
